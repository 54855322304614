// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SWR from "../../../../node_modules/rescript-swr/lib/es6_global/src/SWR.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Fetch from "../../../../node_modules/bs-fetch/lib/es6_global/src/Fetch.bs.js";
import * as Js_exn from "../../../../node_modules/rescript/lib/es6/js_exn.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as $$Promise from "../../../../node_modules/@ryyppy/rescript-promise/lib/es6_global/src/Promise.bs.js";
import * as Rollbar from "../../../../node_modules/rescript-rollbar/lib/es6_global/src/Rollbar.bs.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as URLUtils$Showside from "./URLUtils.bs.js";
import * as Caml_js_exceptions from "../../../../node_modules/rescript/lib/es6/caml_js_exceptions.js";
import * as RollbarInstance$Showside from "./RollbarInstance.bs.js";
function truncateTo30Characters(str) {
  if (str.length > 30) {
    return str.slice(0, 30) + "...";
  } else {
    return str;
  }
}
function tryExtractUsefulErrorMessageFromResponse(res) {
  return Fetch.$$Response.json(res).then(function (json) {
    var rawJson = JSON.stringify(json);
    var obj = Js_json.decodeObject(json);
    if (obj === undefined) {
      return Promise.resolve({
        exn: {
          RE_EXN_ID: SWR.ServerError,
          _1: truncateTo30Characters(rawJson)
        },
        displayError: truncateTo30Characters(rawJson),
        rawError: rawJson,
        statusCode: res.status
      });
    }
    var displayError = Belt_Option.flatMap(Js_dict.get(Caml_option.valFromOption(obj), "error"), Js_json.decodeString);
    if (displayError !== undefined) {
      return Promise.resolve({
        exn: {
          RE_EXN_ID: SWR.ServerError,
          _1: displayError
        },
        displayError: displayError,
        rawError: rawJson,
        statusCode: res.status
      });
    } else {
      return Promise.resolve({
        exn: {
          RE_EXN_ID: SWR.ServerError,
          _1: truncateTo30Characters(rawJson)
        },
        displayError: truncateTo30Characters(rawJson),
        rawError: rawJson,
        statusCode: res.status
      });
    }
  });
}
function handleServerResponse(url, res) {
  var pathname = Belt_Option.getWithDefault(URLUtils$Showside.getPathname(URLUtils$Showside.resolveRelativePath(url)), url);
  var statusCode = res.status;
  if (statusCode >= 200 && statusCode < 300) {
    return Promise.resolve({
      TAG: /* Ok */0,
      _0: res
    });
  } else {
    return $$Promise.$$catch(tryExtractUsefulErrorMessageFromResponse(res), function (e) {
      return Promise.resolve({
        exn: e,
        displayError: "Unknown server error",
        rawError: Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_js_exceptions.as_js_exn(e), function (prim) {
          return prim.message;
        }), ""),
        statusCode: statusCode
      });
    }).then(function (error) {
      return {
        TAG: /* Error */1,
        _0: {
          exn: error.exn,
          displayError: "Error " + statusCode.toString() + " in API " + pathname + ": " + error.displayError + "",
          rawError: error.rawError,
          statusCode: statusCode
        }
      };
    });
  }
}
function reportAllServerErrors(res, url) {
  return res.then(function (param) {
    return handleServerResponse(url, param);
  }).then(function (result) {
    if (result.TAG === /* Ok */0) {
      return Promise.resolve(result._0);
    }
    var error = result._0;
    return RollbarInstance$Showside.error3(error.exn, error.displayError, {
      requestUrl: url,
      rawError: error.rawError
    }).then(function (param) {
      return Promise.reject(error.exn);
    });
  });
}
function ignoreNotFoundError(res, url) {
  return res.then(function (__x) {
    return handleServerResponse(url, __x);
  }).then(function (result) {
    if (result.TAG === /* Ok */0) {
      return Promise.resolve(result._0);
    }
    var error = result._0;
    if (error.statusCode === 404) {
      return Promise.reject({
        RE_EXN_ID: SWR.ServerError,
        _1: "Not found"
      });
    } else {
      return RollbarInstance$Showside.error3(error.exn, error.displayError, {
        requestUrl: url,
        rawError: error.rawError
      }).then(function (param) {
        return Promise.reject(error.exn);
      });
    }
  });
}
var Server = {
  truncateTo30Characters: truncateTo30Characters,
  tryExtractUsefulErrorMessageFromResponse: tryExtractUsefulErrorMessageFromResponse,
  handleServerResponse: handleServerResponse,
  reportAllServerErrors: reportAllServerErrors,
  ignoreNotFoundError: ignoreNotFoundError
};
function ignoreDecode(json) {
  return {
    TAG: /* Ok */0,
    _0: json
  };
}
function raiseExnIfFound(json, decoder) {
  var decodedObject = Js_json.decodeObject(json);
  var error = Belt_Option.flatMap(Belt_Option.flatMap(decodedObject, function (dict) {
    return Js_dict.get(dict, "error");
  }), Js_json.decodeString);
  var numberOfFields = Belt_Option.getWithDefault(Belt_Option.map(decodedObject, function (object) {
    return Object.keys(object).length;
  }), 0);
  if (error !== undefined && error.trim() !== "" && numberOfFields === 1) {
    return Promise.reject(Js_exn.raiseError(error));
  } else {
    return Curry._1(decoder, json);
  }
}
var SuccessfulResponseErrorBody = {
  raiseExnIfFound: raiseExnIfFound
};
function reportAllDecodingErrors(res, url, decoder, errorEncoder) {
  var pathname = Belt_Option.getWithDefault(URLUtils$Showside.getPathname(URLUtils$Showside.resolveRelativePath(url)), url);
  return res.then(function ($$fetch) {
    return Fetch.$$Response.json($$fetch).then(function (json) {
      var decoded = raiseExnIfFound(json, decoder);
      var errorEncoder$1 = Belt_Option.getWithDefault(errorEncoder, function (e) {
        return Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_js_exceptions.as_js_exn(Caml_js_exceptions.internalToOCamlException(e)), function (prim) {
          return prim.message;
        }), "Unknown error");
      });
      return $$Promise.$$catch(decoded, function (e) {
        var errorMessage = "Failed to decode response of API " + pathname + ": Rejected with " + Belt_Option.getWithDefault(Rollbar.$$Error.fromRescript(e).message, "unknown error") + "";
        var error = {
          RE_EXN_ID: SWR.DecodeError,
          _1: errorMessage
        };
        return RollbarInstance$Showside.error3(error, errorMessage, {
          requestUrl: url,
          rawError: e
        }).then(function (param) {
          return Promise.reject(error);
        });
      }).then(function (decoded) {
        if (decoded.TAG === /* Ok */0) {
          return Promise.resolve(decoded._0);
        }
        var e = decoded._0;
        var errorMessage = "Failed to decode response of API " + pathname + ": " + Curry._1(errorEncoder$1, e) + "";
        var error = {
          RE_EXN_ID: SWR.DecodeError,
          _1: errorMessage
        };
        return RollbarInstance$Showside.error3(error, errorMessage, {
          requestUrl: url,
          rawError: e
        }).then(function (param) {
          return Promise.reject(error);
        });
      });
    });
  });
}
var Decoding = {
  ignoreDecode: ignoreDecode,
  SuccessfulResponseErrorBody: SuccessfulResponseErrorBody,
  reportAllDecodingErrors: reportAllDecodingErrors
};
function $$fetch$1(asyncDecoder, method_Opt, body, headers, errorEncoder, reportDecodingError, reportServerError, decoder, url) {
  var method_ = method_Opt !== undefined ? method_Opt : /* Get */0;
  var reportServerError$1 = Belt_Option.getWithDefault(reportServerError, reportAllServerErrors);
  var decoder$1 = Belt_Option.getWithDefault(asyncDecoder, function (json) {
    return Promise.resolve(Curry._1(decoder, json));
  });
  var reportDecodingError$1 = Belt_Option.getWithDefault(reportDecodingError, reportAllDecodingErrors);
  return Curry._4(reportDecodingError$1, Curry._2(reportServerError$1, $$Promise.$$catch(fetch(url, Fetch.RequestInit.make(method_, headers, body, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)), function (err) {
    return Promise.reject({
      RE_EXN_ID: SWR.NetworkError,
      _1: Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_js_exceptions.as_js_exn(err), function (prim) {
        return prim.message;
      }), "Network error")
    });
  }), url), url, decoder$1, errorEncoder);
}
export { Server, Decoding, $$fetch$1 as $$fetch };
/* SWR Not a pure module */